@import "../../base/fn";

.weui_tabbar {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 100%;
    background-color: #f7f7fa;

    &:before {
        .setTopLine(#979797);
    }
}

.weui_tabbar_item {
    display: block;
    flex: 1;
    padding: 7px 0 0;
    -webkit-tap-highlight-color: transparent;

    &.weui_bar_item_on {
        .weui_tabbar_label {
            color: #09BB07;
        }
    }
}

.weui_tabbar_icon {
    margin: 0 auto;
    width: 24px;
    height: 24px;

    img {
        display: block;
        width: 100%;
        height: 100%;
    }

    & + .weui_tabbar_label {
        margin-top: 5px;
    }
}

.weui_tabbar_label {
    text-align: center;
    color: @globalTextColor;
    font-size: 12px;
}